$background: #09142e;
$colorback: white;
$backapp: #f4f4f4;
$colorapp: black;
$fontsize: 14px; //12px;
$themecolor: #224372;
$itemcolor: #6d6d6d; // #8E95A5;
$fontFamily: "roboto";
$primarycolor: #ea3f3f;
$negativecolor: #ea3f3f;
$positivecolor: #2a875d;
$desccolor: #7f8696; //#8E95A5;//
$precioSAPColor: #09142e; //black;//#18b1ffe6;

@font-face {
    font-family: FontAwesome5Regular;
    src: url("../fonts/FontAwesome5FreeRegular400.otf");
}

@font-face {
    font-family: FontAwesome;
    src: url("../fonts/FontAwesome4.otf");
}

@font-face {
    font-family: iconos;
    src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: iconos;
    src: url("../fonts/FontAwesome4.otf");
    unicode-range: f023;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.primarycolor {
    color: $primarycolor !important;
}

.itemcolor {
    color: $itemcolor !important;
}

.f-9 {
    font-size: 9px;
}

.f-10 {
    font-size: 10px;
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-13-5 {
    font-size: 13.5px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.fontAwesome {
    font-family: iconos;
}

[id*="eliminarPedido"] .modal-body {
    min-height: auto;
}

body {
    font-family: $fontFamily;
}

// Configurar scroll vertical solo para menu de productos en tienda
::-webkit-scrollbar {
    display: none;
}

// -- Configurar scroll vertical para menu producto en Tienda
// #scrollbarMenuProducto ::-webkit-scrollbar {
//   width: 8px;
// }

// #scrollbarMenuProducto ::-webkit-scrollbar-track {
//   background: #dcdddf;
// }

// #scrollbarMenuProducto ::-webkit-scrollbar-thumb {
//   background: #8E95A5;
// }

// #scrollbarMenuProducto ::-webkit-scrollbar-thumb:hover {
//   background: #8E95A5;
// }

// Configurar menu producto en Tienda --

.scrollbar {
    width: auto;
    margin: 0;
}

.scrollbar::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: $background;
    border-radius: 5px;
}

#cuentaCorriente .modal-content {
    min-height: 300px;
    width: 100%;
}

#modal {
    display: none;
}

#login:before {
    background-image: url("../assets/background.jpg");
    position: fixed;
    background-position: center;
    display: block;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    content: "";
}

#login {
    .brand-logo {
        width: 80px;
        height: 60px;
        margin-bottom: 40px;
    }

    &.register {
        .brand-logo {
            width: 75px;
            height: 55px;
            margin-bottom: 20px;
            margin-top: 0px;
        }

        label i {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1px solid;
            border-radius: 16px;
            text-align: center;
            line-height: 1.2;
            font-style: normal;
        }
    }
}

// #notificaciones {
//   padding-bottom: 80px;
// }

#reclamoModal .modal-dialog {
    margin: auto;
}

#reclamoModal .modal-header {
    display: block;
    border: 0;
    padding: 0;
    padding-top: 10px;
}

#reclamoModal .modal-header .close {
    float: inherit;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
}

#reclamoModal .modal-content {
    width: 100%;
    height: 90vh;
}

#reclamoModal .modal-footer .cancel {
    color: $themecolor;
}

.reclamo-send {
    background: $themecolor;
    color: white;
    min-width: 89px;
    min-height: 34px;
    max-height: 34px;
    border-radius: 17px;
}

#call-menu {
    height: 23px;
    margin: auto 15px;
    cursor: pointer;
}

#received {
    position: relative;
    display: block;
}

#received p {
    margin: 0;
}

#received .modal-body {
    padding: 1.5rem 0.8rem;
}

#received .modal-content {
    background: $primarycolor;
}

#received .modal-dialog {
    display: flex;
    justify-content: center;
}

.agregar-pedido {
    background: $primarycolor; // #EA3F3F;
    color: white;
    font-size: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}

.arrow-up {
    width: 35px;
    height: auto;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #8e95a5;
    color: white;
}

.back-arrow-box {
    top: 0;
    margin-top: 14px;
    margin-left: 22px;
    z-index: 99999999;
    color: #343435;
    width: 13px;
    height: 13px;
    cursor: pointer;
    position: fixed;
}

.badge-theme-red {
    color: white;
    background-color: $primarycolor;
}

.barra-enviar {
    position: fixed;
    bottom: 56px;
    left: 0px;
    right: 0px;
    background: white;
    height: 50px;
}

.barra-precio {
    position: fixed;
    bottom: 105px;
    left: 0px;
    right: 0px;
    background: white;
    height: 40px;
    border-bottom: 2px solid lightgray;
    padding: 15px;
}

.barra-alert-input-vacio {
    position: fixed;
    bottom: 132px;
    //width: 92%;
    // background: white;
    height: 40px;
    // border-bottom: 2px solid lightgray;
    padding: 15px;
}

.barra-cantidad-devolver {
    position: fixed;
    bottom: 105px;
    left: 0px;
    right: 0px;
    background: white;
    height: 40px;
    border-bottom: 2px solid lightgray;
    padding: 12px;
    padding-top: 15px;
}

.barra-cantidad-devolver-cerrada {
    position: fixed;
    bottom: 56px;
    left: 0px;
    right: 0px;
    background: white;
    height: 50px;
    border-bottom: 2px solid lightgray;
    padding: 12px;
    padding-top: 18px;
}

.barra-enviar-club-paradaonline {
    position: fixed;
    bottom: 95px;
    left: 0px;
    right: 0px;
    background: white;
    height: 50px;
    border-bottom: 2px solid lightgray;
    z-index: 1000;
}

.barra-terminos-condiciones {
    position: fixed;
    bottom: 56px;
    left: 0px;
    right: 0px;
    background: white;
    height: 40px;
    border-bottom: 2px solid lightgray;
    padding: 15px;
    z-index: 1000;
}

div.producto-no-editorial-ver-caracteristicas {
    color: #224372;
    margin-top: 0px;
    font-weight: bold;
    a:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

#helpFreshdeskBottom {
    width: 32px;
    height: 33px;
    border-radius: 100%;
    top: 6px;
    right: 6px;
    margin: 0px;
    padding: 0px;
    z-index: 999999999;
    position: fixed;

    #helpFreshdesk {
        border-radius: 100%;
        width: auto;
        height: auto;
        margin: auto;
        padding: auto;
        cursor: pointer;
        top: auto;
        right: auto;
        background-size: cover;
        display: block;
        position: absolute;
        z-index: 99999999999999;
    }
}

.bell {
    font-family: FontAwesome5Regular;
    font-size: 20px;
    margin-right: 18px;
    color: $itemcolor;
}

.bell:hover {
    color: $primarycolor;
}

.box {
    margin-top: 20px;
    background-color: white;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0px 2px 1px 0px #e0e0e0;
    line-height: 1.2;
}
.boxFlasheo {
    margin-top: 12px;
    background-color: white;
    border-radius: 12px;
    padding: 7px 1px 1px 7px;
    box-shadow: 0px 2px 1px 0px #e0e0e0;
    line-height: 1.2;
}

#reclamos .box {
    padding: 10px;
}

#pedidos .box {
    margin-top: 14px;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 2px 1px 0px #e0e0e0;
    line-height: 1.2;
    padding: 7px 9px 2px 9px;
}

#cargasdevoluciones .box {
    margin-top: 14px;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0px 2px 1px 0px #e0e0e0;
    line-height: 1.2;
    padding: 7px 9px 2px 9px;
}

.button-theme {
    background-color: $themecolor;
    color: white;
    border-radius: 17px;
    font-size: $fontsize;
}

.btn:hover {
    color: inherit;
}

.btn-enviar {
    background: #224372;
    color: white;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    width: 90px;
    height: 33px;
}

.header {
    padding-top: 26px;
    margin-bottom: 23px;
}

.headerRegVenta {
    padding-top: 15px;
    margin-bottom: 0px;
}
.headerReserva {
    padding-top: 15px;
    margin-bottom: 0px;
}
.datos-reclamo {
    background-color: white;
    padding: 5px 0px 0px 0px;
    color: $itemcolor; // #6D6D6D;
    width: 100%;
}

.datos-reclamo-top {
    font-weight: 400;
    max-height: 23px;
    transition: all 1s;
    transition-delay: 0ms;
    overflow-x: hidden;
}

.desc-novedades {
    font-size: 11.5px;
    color: $desccolor;
    font-weight: 500;
}

.novedadesvalor {
    font-size: 13px;
    color: #343435;
    font-weight: 500;
    margin-right: 10px;
}

.dias-novedades {
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
}

#lista-reclamos-ejemplar .desc-reclamo {
    margin-top: 0px;
}

#lista-reclamos-ejemplar .desc-reclamo span {
    padding-left: 5px;
}

#lista-reclamos-ejemplar .box {
    padding-left: 12px;
    padding-right: 10px;
}

.desc-reclamo {
    font-size: 11.5px;
    color: $desccolor; // #6D6D6D;
    font-weight: 500;
}

.reclamovalor {
    font-size: 13px;
    color: #343435;
    font-weight: 500;
}

.desc-nropedido {
    font-size: 11.5px;
    color: $itemcolor; // #6D6D6D;
    font-weight: 400;
}

.desc-reclamo span {
    font-size: 13px;
    color: #343435;
}

.desc-reclamo div {
    font-size: 13px;
    color: #343435;
}

.descargarcc {
    border-radius: 17px;
    border: 1px solid $itemcolor;
    font-size: 10px;
    padding: 5px 10px;
}

.descargarcomp {
    border-radius: 17px;
    border: 1px solid $itemcolor;
    font-size: 10px;
    padding: 2px 10px;
}

.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}

.eraser {
    margin-bottom: 14px;
    font-size: 10px;
    display: flex;
    justify-content: space-evenly;
}

#modalInfoRegister .btn,
#errorloginModal .btn {
    background: none;
    background-color: none;
    border: 0;
}

#modalInfoRegister {
    ul {
        padding: 0;
        margin: 20px 0 0;
    }

    .modal-body {
        min-height: auto;
    }

    li {
        color: #dc3545;
        text-align: left;
        list-style: none;
        line-height: 1;
        margin: 0 0 2px 0;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: currentColor;
            border-radius: 10px;
            margin: 0 3px 0 0;
        }

        &:nth-last-child(3) {
            color: #007bff;
        }

        &:nth-last-child(2) {
            color: #28a745;
        }
        &:nth-last-child(1) {
            color: #ffc107;
        }
    }
}

#modalInfoTelefono .btn,
#modalInfoDomicilio .btn,
#errorloginModal .btn {
    background: none;
    background-color: none;
    border: 0;
}

#modalInfoTelefono {
    ul {
        color: #dc3545;

        &:nth-last-child(1) {
            padding: 0px;
        }
    }

    .modal-body {
        min-height: auto;
    }

    li {
        color: #dc3545;
        text-align: left;
        list-style: none;
        line-height: 1.1;
        margin: 3px 0 3px 0px;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: currentColor;
            border-radius: 10px;
            margin: 0 3px 0 0;
        }
    }
}

#modalInfoDomicilio {
    ul {
        color: #dc3545;

        &:nth-last-child(1) {
            padding: 0px;
        }
    }

    .modal-body {
        min-height: auto;
    }

    li {
        color: #dc3545;
        text-align: left;
        list-style: none;
        line-height: 1.1;
        margin: 3px 0 3px 0px;

        &:before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: currentColor;
            border-radius: 10px;
            margin: 0 3px 0 0;
        }
    }

    .modalTitle {
        color: #dc3545;
    }
}

#errorloginModal .modal-body {
    min-height: auto;
}

.expandir {
    margin-left: 15px;
    width: 13px;
    height: 13px;
    color: #8e95a5;
    transition: all 1s;
}

.fecha-hoja-ruta {
    font-size: $fontsize;
    margin-top: -11px;
    margin-bottom: 30px;
    color: #8e95a5;
}

#info-reclamo .box {
    background-color: #c9c9c9;
}

#pedidos .filter {
    margin-top: -165px;
}

#cargasdevoluciones .filter {
    margin-top: -165px;
}

#registrarventa .filter {
    margin-top: -168px;
}

.filter {
    background-color: white;
    padding: 20px 15px;
    color: #8e95a5;
    margin-bottom: 23px;
    width: 100%;
    margin-top: -130px;
    transition: all 0.5s;
}

.fill-red {
    color: $primarycolor;
}

.leaflet-container {
    height: 250px;
    margin: 0 -15px;
}

.lista-alertas {
    margin: 0;
    padding: 15px 0 0 0px;
    list-style: none;
}

.lista-alertas li {
    margin-bottom: 15px;
}

.maestro .filter {
    margin-top: -219px;
}

.MisSolicitudes .filter {
    margin-top: -219px;
}

.modal-body {
    border: 0 !important;
    padding-bottom: 0;
    min-height: 255px;
}

.motivo-reclamo {
    background: $primarycolor;
    width: auto;
    border-radius: 16px;
    color: white;
    padding: 4px 8px;
    font-weight: 400;
}

#ventanascanner .modal-body {
    padding: 0;
    margin: 1rem 1rem 0 1rem;
    max-width: 95vw;
    overflow: hidden;
}

#errorModal .modal-body {
    min-height: auto;
}

#pdf-loader .modal-content {
    width: 100%;
}

.modal-content {
    width: 90%;
}

.modal-footer {
    border: 0 !important;
    padding-top: 0;
}

.not-found {
    margin-top: 20px;
    margin-left: 4px;
}

.nota-reclamo input {
    transition: height 0.3s;
}

.nota-reclamo input:focus {
    height: 90px !important;
}

.filtericon {
    margin-top: -2px;
    margin-left: 5px;
    font-size: $fontsize;
    height: 15px;
}

.filterdiv {
    color: $itemcolor;
    font-size: 11px;
    height: 30px;
    margin-top: 2px;
}

.filter-input {
    padding-left: 7px;
    padding-right: 13px;
    font-size: 11px;
}

.notif {
    width: 100%;
    height: 84px;
    border-bottom: 1px solid #e7eaed;
    text-align: left;
}

.not-seen {
    background-color: white;
}

.notif-icon {
    width: 44px;
    height: 44px;
    margin: 0px 20px;
    border: 1px solid #cdcfd1;
    border-radius: 50%;
}
#notificaciones .icon {
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 14px;
    height: 19px;
}

#notificacionesNum {
    position: relative;
    bottom: 37px;
    right: 40px;
    font-size: 10px;
    color: white;
    width: 14px;
    height: 14px;
    background-color: #ea3f3f;
    line-height: 1.5em;
    text-align: center;
    z-index: 0;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-weight: bold;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);

    -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 35), 0px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.35), 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.35), 0px 1px 1px rgba(0, 0, 0, 0.2);

    -webkit-border-radius: 4em;
    -moz-border-radius: 4em;
    border-radius: 4em;

    opacity: 1;
}

.icon {
    margin: auto;
    width: 22px;
    height: 22px;
    color: $itemcolor;
}

.icon:hover {
    color: $primarycolor;
}

.index .brand-logo {
    width: 80px;
    height: 60px;
    margin-bottom: 20px;
}

.recuperar {
    font-size: 24px;
    height: 34px;
    margin: 0 auto 80px;
}

.menuimg {
    width: 17px;
    height: 15px;
    color: $itemcolor;
}

.menu-dots {
    position: fixed;
    bottom: 56px;
    right: 0;
    background: $background;
    min-width: 160px;
    padding-right: 5px;
}

.navbar-logo {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
}

.navbar-user {
    right: 0;
    top: 0;
    bottom: 0;
    height: 30px;
    position: absolute;
    margin: auto;
    //background-color: blue;
}

.callMenu {
    height: 46px;
    background: rgba(0, 0, 0, 0);
    color: black;
    z-index: 1059;
}

.callMenu .brand-logo {
    margin-top: 8px;
    width: 55px;
    height: 40px;
}

.ccdesc {
    font-size: 11.5px;
    color: $desccolor;
    font-weight: 500;
}

.ccvalor {
    font-size: 13px;
    color: #343435;
    font-weight: 500;
}

.suscdesc {
    font-size: 11.5px;
    color: $desccolor;
    font-weight: 500;
}

.suscvalor {
    font-size: 13px;
    color: #343435;
    font-weight: 500;
}

.hrutadesc {
    font-size: 11.5px;
    color: $desccolor;
    font-weight: 500;
}

.hrutavalor {
    font-size: 13px;
    color: #343435;
    font-weight: 500;
}

#cargarpedido .header {
    margin-bottom: 15px;
}

#cargarpedido .tablinks {
    padding: 18px 0;
}

#cargarpedido .titulo-producto {
    margin-bottom: 12px;
}

#cargarpedido {
    padding-bottom: 50px;
    line-height: 1.2;
}

#historial-puntos {
    #terminos-condiciones {
        position: relative;
        bottom: 45px;
        padding-bottom: 10px;
    }
}

#datePicker {
    font-size: 11px !important;
}

@media screen {
}

.pedido {
    #historial-puntos {
        #terminos-condiciones {
            position: relative;
            padding-bottom: 10px;
            line-height: 1.5;
        }
    }
}

#registrarventa {
    padding-bottom: 135px;
}

#cargarpedido .pedido {
    padding-bottom: 60px;
}

.historial {
    padding-bottom: 65px;
}

.historial-canje {
    display: flex !important;
    justify-content: center !important;
}

.whatsapp-share-button {
    z-index: 1000;
    position: relative;
}

#novedadesDescList {
    padding-bottom: 60px;

    .days {
        background: white;
        min-height: 48px;
        margin-bottom: 12px;
        padding: 0px 12px 0px 6px;
        border-radius: 6px;
        cursor: "pointer";
    }

    .span-item-nov.div-item-nov {
        padding: 5px 0;
    }

    .span-item-nov {
        font-weight: 700;
        font-size: 16px;
    }
}

#cargarpedido .days {
    background: white;
    min-height: 48px;
    margin-bottom: 12px;
    padding: 0px 6px 0px 6px;
    border-radius: 6px;
}

#cargarpedido .cardRegVenta {
    background: white;
    margin-bottom: 5px;
    padding: 7px 5px 0px 0px;
    border-radius: 6px;
}

.carousel-img:hover {
    cursor: pointer;
}

.descripcion-banner {
    background-color: "#EA3F3F";
    color: #5775aa;
    font-weight: "bold";
    font-size: "16px";
    position: relative;
    top: 30px;
}

#cargarpedido.tienda {
    .itemCarTienda {
        margin: 23px 0 0;
    }

    .titulo-producto {
        display: none;
    }

    [placeholder="Buscar"] {
        margin-bottom: 23px !important;
    }

    .pedido > div {
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
    }

    .itemTienda {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 5px;
        flex-flow: column;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);

        > div {
            padding: 5px 10px 10px 10px;
        }

        figure img {
            width: 100%;
            max-width: 189px;
            height: auto;
        }

        figure {
            padding: 20px 0 0;
            text-align: center;
            margin: 0;
            background-color: #f1f1f1;
            min-width: 100%;
            min-height: 230px;
            margin-left: -10px;
            margin-right: -10px;
            margin-top: -5px;
        }
    }
}

@media (min-width: 768px) {
    #cargarpedido.tienda {
        .itemTienda {
            width: calc(50% - 10px);
        }
    }

    .titleTagItem {
        height: 25px;
        padding-top: 5px;
        font-size: 14px;
        // max-width: 224px;
        font-weight: 500;
        margin: 10px 16% 10px 16%;
        border-radius: 4px;
        background-color: #ff9307;
        color: white;
    }

    .titleTagPreVentaItem {
        height: 25px;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 16% 10px 16%;
        border-radius: 4px;
        background-color: #70ad47;
        color: white;
        text-transform: uppercase;
    }
}

@media (min-width: 800px) {
    #cargarpedido.tienda {
        .itemTienda {
            width: calc(50% - 10px);
        }
    }

    .movimientos-contenedor {
        min-height: 500px;
    }
}

@media (min-width: 1023px) {
    #cargarpedido.tienda {
        .itemTienda {
            width: calc(33% - 10px);
        }
    }
}

.movimientos-contenedor {
    min-height: 170px;
}

@media (min-height: 800px) {
    .movimientos-contenedor {
        min-height: 300px;
    }
}

@media (min-height: 1000px) {
    .movimientos-contenedor {
        min-height: 450px;
    }
}

#editarpedido .days {
    background: white;
    height: 46px;
    margin-bottom: 20px;
    padding: 0px 18px;
    border-radius: 6px;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.backlogin {
    background-image: url("../assets/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $background !important;
    color: $colorback !important;
}

.backapp {
    background-color: $backapp !important;
    color: $colorapp !important;
}

.backwhite {
    background-color: white !important;
}

.backreclamo {
    background-color: #e2e2e2 !important;
}

.bottom-nav {
    display: flex !important;
    background-color: $background !important;
    justify-content: space-around !important;
    align-items: center;
    position: fixed;
    height: 56px;
    width: 100%;
    bottom: 0;
    z-index: 1058;
}

.day-tab {
    padding: 10px 23px;
    color: $themecolor;
    font-size: 11px;
    border-radius: 20px;
    cursor: pointer;
}

.day-tab.active {
    background: $themecolor;
    color: white;
}

.h-100vh {
    height: 100vh;
}

.index {
    min-height: calc(100vh - 46px);
    height: 100%;
}

.index-menu {
    width: 90%;
    max-width: 600px;
    margin: auto;
    padding-top: 25px;
}

.index-menu-box {
    font-size: 14px;
    font-weight: 300;
    width: 135px;
    height: 105px;
    margin-bottom: 15px;
    border: 1px solid white;
    border-radius: 6px;
}

.index-menu-box img {
    margin-bottom: 0px;
}

.mh-0 {
    max-height: 0;
}

.profile-pic {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
}

.printer {
    position: relative;
    color: #4870ab;
    background: #c7e6f9;
    border-radius: 50%;
    margin-right: 10px;
    width: 34px;
    height: 34px;
}

.printer span {
    position: absolute;
    font-family: "FontAwesome";
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;
    font-size: 15px;
    height: 22px;
    width: 15px;
}

.printer-text {
    color: $themecolor;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.printers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-right: 10px;
}

.profile-dropdown {
    display: flex;
    margin-right: 30px;
}

.profile-dropdown .icon {
    margin-left: 0px;
    margin-right: 15px;
}

.pic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 43px;
    height: 30px;
}

#lista-reclamos-ejemplar {
    padding-bottom: 110px;
    margin-top: 10px;
}

#devoluciones .cards {
    padding-bottom: 185px;
}

#Novedades .cards {
    padding-bottom: 125px;
}

#reclamos .cards {
    padding-bottom: 125px;
}

#hojaRuta .cards {
    padding-bottom: 125px;
}

#cuentaCorriente .cards {
    padding-bottom: 125px;
}

#Publicaciones .cards {
    padding-bottom: 125px;
    margin-top: 22px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    justify-items: center;
}

.categoria {
    font-size: 12px;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;
}

.publicard {
    font-size: 14px;
    background-color: #ea3f3f;
    border-radius: 12px;
    width: 100%;
    height: 125px;
    padding: 0 15px;
    cursor: pointer;
}

.maestro .title {
    //font-size: 19px;
    font-size: 24px;
    font-weight: 300;
    color: $themecolor;
}

.maestro .filterdiv {
    margin-top: -1px;
}

.MisSolicitudes .title {
    //font-size: 19px;
    font-size: 24px;
    font-weight: 300;
    color: $themecolor;
}

.MisSolicitudes .filterdiv {
    margin-top: -1px;
}

.modal-backdrop {
    z-index: 0;
}

.saldo {
    color: white;
    background-color: $negativecolor;
    padding-top: 17px;
    padding-bottom: 20px;
    margin-bottom: 23px;
    border-radius: 12px;
}

.saldo.negativo {
    color: white;
    background-color: $negativecolor;
}

.saldo.positivo {
    color: white;
    background-color: $positivecolor;
}

.seleccionar {
    right: 0;
    position: relative;
}

.scrollTop {
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.seleccionar svg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;
    width: 9px;
    height: 9px;
}

.tablinks {
    padding: 8px 0;
    cursor: pointer;
}

.tabs .active {
    border-bottom: 2px solid red;
    margin-bottom: -1px;
}

.telefono {
    background: #3fd600;
    color: white;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 12px;
    border-radius: 17px;
}

.telefono a {
    position: relative;
}

.telefono svg {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;
    width: 10px;
    height: 10px;
}

.telefono-reclamo {
    width: 23px;
    height: 23px;
    background: #3fd600;
    color: white;
    border-radius: 50%;
    position: relative;
}

.telefono-reclamo:hover {
    color: white;
}

.telefono-reclamo svg {
    width: 13px;
    height: 13px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}

.title {
    font-size: 24px;
    font-weight: 300;
    color: $themecolor;
}

.titleReserva {
    font-size: 17px;
    font-weight: bold;
}

.titulo-producto {
    color: $primarycolor;
    font-size: 16px;
    font-weight: 400;
}

.remember-text {
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
    margin-top: -38px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    // background-color: $background;
    font-size: $fontsize;
    color: white;
    // background: white;
}

.App-link {
    color: #61dafb;
}

.register .login-form {
    margin: 10px auto;
}

.register {
    .login-form {
        width: 310px;
    }

    .theme-input {
        width: 100%;
        margin-bottom: 13px;
    }

    h1 {
        font-size: 24px;
        font-weight: 400;
        margin: 0 0 30px;
    }

    label {
        display: flex;
        justify-content: space-between;
        text-align: left;
        line-height: 1;
        margin: 0 0 3px;
        font-size: $fontsize;
    }

    .theme-input-box {
        padding-left: 6px;
    }
}

.login-form {
    height: 346px;
    width: 280px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 135px auto 0;
    position: absolute;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#codigodebarras .modal-dialog {
    width: fit-content;
    margin: 0.5rem auto;
}

#ventanascanner {
    width: fit-content;
    max-width: 100vw;
}

.version {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 11px;
}

.versionLogin {
    position: fixed;
    margin-top: 620px;
    left: 0;
    right: 0;
    font-size: 11px;
}

.banner-sharebutton-container {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    align-content: center;
    justify-content: center;
    text-align: center !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 60px;

    .image-banner-container {
        justify-content: center;
        display: flex;
        text-align: center !important;
    }

    .title-banner-component {
        display: block !important;
        padding: 0;
        justify-content: center;
        display: flex;
        margin-bottom: 5px;
    }

    .title-banner {
        font-size: 20px;
    }

    img {
        flex-direction: row;
        justify-items: center;
        height: 480px;
        max-height: 480px;
        width: 100%;
        max-width: 330px;
    }

    .share-button-container {
        width: 100%;
        max-width: 330px;
        display: flex;
        display: flex;
        justify-content: right;
        justify-items: center;
    }
}

.productos-vigentes-por-categoria {
    margin-bottom: 5px;
}

.versionFooter {
    padding-top: 15px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 11px;
}
//////////LOGIN//////////
/* ThemeInput */

.div-button {
    display: inline-block;
    min-width: 88px;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 100%;
}

.remember {
    margin-bottom: 28px;
}

.theme-input {
    margin-bottom: 28px;
    font-size: $fontsize;
    line-height: 24px;
    width: 279px;
    height: 34px;
    position: relative;
    background-color: white;
    font-family: Roboto, sans-serif;
    border-radius: 6px;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
}

.theme-input .gray-bar {
    border-top: none rgb(224, 224, 224);
    border-left: none rgb(224, 224, 224);
    border-right: none rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    position: absolute;
    width: 100%;
}

.theme-input .color-bar {
    border-top: none rgb(0, 188, 212);
    border-left: none rgb(0, 188, 212);
    border-right: none rgb(0, 188, 212);
    border-bottom: 2px solid $themecolor;
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.theme-input .color-bar.active {
    transform: scaleX(1);
}

.theme-input-box {
    padding-left: 16px;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    font: inherit;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    box-sizing: border-box;
}

.theme-label-input {
    position: absolute;
    line-height: 22px;
    top: 6px;
    left: 10px;
    margin: 0;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    transform: scale(1) translate(0px, 0px);
    transform-origin: left top;
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.3);
}

.theme-div {
    position: absolute;
    opacity: 0;
    color: rgba(0, 0, 0, 0.3);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    bottom: 12px;
}

.theme-button {
    border: 1px solid white;
    box-sizing: border-box;
    display: inline-block;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    height: 36px;
    line-height: 36px;
    width: 113px;
    border-radius: 17px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background-color: rgba(0, 0, 0, 0);
    color: $colorback;
    text-align: center;
    font-size: $fontsize;
    padding: 0px 15px;
}

.theme-button:hover {
    background-color: rgba(0, 188, 212, 0.7);
}
// Estilos boton desbloquear usuario
.theme-button-boton-desbloquear {
    border: 1px solid white;
    box-sizing: border-box;
    display: inline-block;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    padding: 0;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    height: 36px;
    line-height: 36px;
    width: 200px;
    border-radius: 17px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0;
    background-color: rgba(0, 0, 0, 0);
    color: $colorback;
    text-align: center;
    font-size: $fontsize;
    padding: 0 15px;
}

.theme-button-boton-desbloquear:hover {
    background-color: rgba(0, 188, 212, 0.7);
}
/* ///////INPUT FOCUS///////// */

.background-themecolor {
    background-color: $themecolor;
    color: rgb(255, 255, 255);
}

.font-color {
    color: $themecolor;
}

.label-focus {
    transform: scale(0.75) translate(0px, -28px);
}

.div-focus {
    opacity: 1;
}
/* ThemeTopBar */

.theme-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding-top: 0px;
    letter-spacing: 0px;
    font-size: 24px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    height: 64px;
    line-height: 64px;
    flex: 1 1 0%;
}

.theme-bar {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 0px;
    position: relative;
    z-index: 5;
    width: 100%;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
}
///////////   /LOGIN   ///////////
///////////   MENU     ///////////

.menu {
    left: 0;
    background: #09142e;
    height: calc(100vh - 35px);
    height: 100vh;
    z-index: 1060;
    color: white;
}

.transition-menu {
    transition: left 0.5s;
}

.hiddenMenu {
    left: -200%;
}
///////////   /MENU     /////////
///////////   PERFIL     ///////////

.alert-red {
    border: 1px solid red;
}

#perfil {
    background-image: url("../assets/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1060;
}

#perfil .brand-logo {
    width: 70px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
}

#perfil .theme-button {
    height: 25px;
    width: auto;
    line-height: 24px;
    font-size: 11px;
}

.dias-time-input {
    font-size: 13px !important;
    font-weight: 400;
    text-decoration: none !important;
    width: 34px !important;
    color: #343435 !important;
}

.time-inputs {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    padding: 0px 5px;
}

.MuiInput-underline:before {
    border: 0 !important;
}

.flecha-perfil {
    color: $negativecolor;
    width: 12px;
    margin-right: 10px;
}

.leaflet-control-zoom {
    display: none;
}

.leaflet-control .geocoder-control-input {
    background-image: url("../assets/lupaBuscar.png") !important;
    width: 90px !important;
    height: 42px !important;
    background-size: auto !important;
    font-size: 15px !important;
}

.geocoder-control-expanded {
    width: 300px !important;
}

.geocoder-control-expanded .geocoder-control-input {
    background-image: url("../assets/lupa.png") !important;
    width: 100% !important;
}

.gps-perfil {
    font-size: 16px;
}

.gps-mapa {
    z-index: 1000;
    text-align: center;
    position: absolute;
    bottom: 0;
    padding: 3px;
    height: auto;
    margin: 5px;
}

.gps-svg {
    color: $themecolor;
    fill: $themecolor;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-top: -2px;
}

.perfil {
    bottom: 0;
    height: 100%;
    width: 100vw;
    z-index: 1061;
    color: white;
    transition: bottom 0.6s;
}

.perfil-nya {
    text-align: left;
    margin: auto;
    color: $backapp;
    font-size: 16px;
}

.perfil-pic {
    //background-color: $primarycolor;
    color: white;
    border-radius: 100%;
    width: 95px;
    height: 60px;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 0px;
    position: relative;
}

.perfil-pic .pic {
    height: 100%;
    width: 100%;
}

.hiddenPerfil {
    bottom: -100vh;
}

.pvkiosco {
    background-color: $backapp;
    padding-bottom: 20px;
}

.pvkiosco-title {
    background-color: white;
    display: flex;
    color: $themecolor;
    font-size: 14px;
    min-width: 88px;
    min-height: 30px;
    border-radius: 17px;
    line-height: 30px;
    margin-top: -15px;
    padding-left: 16px;
    padding-right: 16px;
}

.ubicacion-perfil {
    color: #343435;
    text-align: left;
}

.ubicacion-perfil input {
    font-size: 13px !important;
}

#map {
    height: 250px;
    width: 250px;
}

#mapSearchContainer {
    position: fixed;
    top: 20px;
    right: 40px;
    height: 30px;
    width: 180px;
    z-index: 110;
    font-size: 10pt;
    color: #5d5d5d;
    border: solid 1px #bbb;
    background-color: #f8f8f8;
}

.pointer {
    position: absolute;
    top: 86px;
    left: 60px;
    z-index: 99999;
}

.geocoder-control-input {
    background-image: url("https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png");
}

.horarios-perfil {
    color: #343435;
    text-align: left;
    margin-bottom: 15px;
}

.info-perfil {
    color: #343435;
    text-align: left;
    margin-bottom: 15px;
}

.booleanos-perfil {
    background-color: white;
    border-radius: 6px;
    color: #343435;
    text-align: left;
}

.booleanos-box {
    background-color: #ffffff;
}
///////////   /PERFIL     /////////
///////////   RECUPERO DE CONTRASEÑA     /////////

#resetpassword {
    padding-top: 135px;
}

#resetpassword .brand-logo {
    width: 80px;
    height: 60px;
    margin-bottom: 30px;
}

.theme-input {
    margin-bottom: 28px;
    font-size: $fontsize;
    line-height: 24px;
    width: 279px;
    height: 34px;
    position: relative;
    background-color: white;
    font-family: Roboto, sans-serif;
    border-radius: 6px;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
}

.theme-input-box {
    padding-left: 16px;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    font: inherit;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    box-sizing: border-box;
}
///////////   /RECUPERO DE CONTRASEÑA     ///////////
/* Customize the label (the container) */
.container3 {
    display: block;
    position: relative;
    padding-left: 33px;
    padding-top: 3px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 300;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default checkbox */
.container3 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark3 {
    position: absolute;
    border: 1px solid #e5e5e5;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.container3:hover input ~ .checkmark3 {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container3 input:checked ~ .checkmark3 {
    background-color: $themecolor;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark3:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark3:after {
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
/* Style the checkmark/indicator */
.container3 .checkmark3:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 27px;
    height: 17px;
}
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $primarycolor;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primarycolor;
}

input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.titleTagItem-container {
    position: relative;
    bottom: 5px;
}

.titleTagPreVentaItem-container {
    position: relative;
    bottom: 12px;
}

.tienda {
    color: black;
    line-height: 1.2;

    .titleTagItem {
        height: 25px;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 500;
        margin: 10px calc(38% - 10%) 10px calc(38% - 10%);
        border-radius: 4px;
        // max-width: 224;
        background-color: #ff9307;
        color: white;
    }

    .titleTagPreVentaItemCarrito {
        color: blue;
    }

    .titleTagPreVentaItem {
        height: 25px;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 500;
        margin: 10px calc(38% - 10%) 10px calc(38% - 10%);
        border-radius: 4px;
        background-color: #70ad47;
        color: white;
        text-transform: uppercase;
    }

    .titleItem {
        font-size: 16px;
        height: 36px;
        font-weight: 400;
        margin: 15px 5px 20px 5px;
    }

    .subTitleItem {
        font-size: 15px;
        font-weight: 700;
        height: 100% !important;
    }

    .priceItem {
        font-size: 15px;
        font-weight: 700;
        font-style: normal;
    }

    .edition {
        font-size: 16px;
        margin: 10px 0 0;
        display: block;
    }

    .footerItem {
        margin: 10px 0 0;
    }

    .stock,
    .noStock {
        padding: 5px 10px;
        border-radius: 10px;
        color: white;
        font-size: 14px;
        font-style: normal;
        background-color: $primarycolor; // #ea3f3f;
    }

    .stock {
        background-color: #80c35c;
    }
}

#PedidoTienda > div {
    display: flex;
    flex-wrap: wrap;
    min-height: unset !important;
    height: 800px !important;
}

#PedidoTienda > div > div {
    margin-right: 10px;
    height: 400px !important;
    margin-bottom: 20px !important;
    margin-top: 1px !important;
}

.tiendaSinItem {
    display: none !important;
    margin: 0px !important;
    height: 0px !important;
}

.cantidades-container {
    width: 120px;
}

.input-of-cantidades {
    font-size: 13.5px;
    width: 55px;
    height: 25px;
    padding: 4px;
    margin: 0 2.5px 0 2.5px;
}

.titlePuntoItem {
    text-align: center;
    height: 25px;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    width: 135px;
    background-color: #007bff00;
    color: black;
    border: 1px solid #000;
    margin-left: auto;
}

.titleClickPuntoItem {
    text-align: center;
    height: 25px;
    padding-top: 5px;
    font-size: 14px;
    width: 135px;
    font-weight: 500;
    border-radius: 4px;
    background-color: red;
    color: white;
    border: 1px solid #000;
}

.alerta-puntos {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#login {
    min-height: 375px;
}

.popUpNotif {
    min-height: 540px;
    width: 400px;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -250px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    z-index: 100000;
    align-items: center;
}

.popup {
    min-height: 540px;
    width: 500px;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -250px;
    margin-top: -250px;
    text-align: center;
    color: white;
    border-style: solid;
    border-radius: 10px;
    background-color: white;
    // background: linear-gradient(214.85deg, rgb(0, 168, 134) 0%, rgb(0, 108, 159) 100%) 0% 0% / contain;
    // background-image: linear-gradient(214.85deg, rgb(0, 168, 134) 0%, rgb(0, 108, 159) 100%);
    // background-position-x: initial;
    // background-position-y: initial;
    // background-size: contain;
    // background-repeat-x: initial;
    // background-repeat-y: initial;
    // background-attachment: initial;
    // background-origin: initial;
    // background-clip: initial;
    // background-color: initial;
    // animation: MoveUpDown 3s linear infinite;
    z-index: -2147483647;
}

@keyframes MoveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-25px);
    }
}

.popup-button {
    //background-color: #3085d6;// #2B78C0;
    border-radius: 5px;
    width: 120px;
    height: 60px;
    cursor: pointer;
}

.popup-mensaje {
    text-align: center;
    font-family: $fontFamily;
    margin: 0 0 0.5em;
    padding: 0;
    color: #595959; //
    // color: black;
    font-size: 2.1em;
    font-weight: 600;
    text-transform: none;
    word-wrap: break-word;
}

.medio-pago {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
}

.password-icon {
    color: $background;
    position: absolute;
    cursor: pointer;
    right: 6px;
    margin-top: 17px;
}

.nombre-distribuidora {
    color: #343435;
    font-size: 13px;
    display: inline-block;
}

@media (max-width: 420px) {
    .nombre-distribuidora {
        color: #343435;
        font-size: 13px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 300px;
    }

    .modificar-tel-info {
        width: 16% !important;
    }

    .slot {
        padding-left: 30px;
    }

    // .titleTagDescuentoItem {
    //     height: 25px;
    //     padding-top: 5px;
    //     font-size: 14px;
    //     font-weight: 500;
    //     margin: 10px 20% 10px 25%;
    //     border-radius: 4px;
    //     background-color: #49bcf2;
    //     color: white;
    //     position: relative;
    //     right: 3em;
    //     top: 2em;
    // }
}

@media (min-width: 421px) {
    .nombre-distribuidora {
        pointer-events: none;
    }

    .direc-completa {
        display: inline !important;
        //color: $itemcolor !important;
    }

    .direc-corta {
        display: none !important;
    }
}

.nombre-distribuidora .tooltiptext {
    //background-color: #224372;
    width: 100%;
    height: 100%;
    color: $itemcolor;
    text-align: center;
    padding: 0;
    /* Position the tooltip */
    position: relative;
    z-index: 6;
}

.modificar-tel-info {
    display: flex;
    width: 5%;
    justify-content: end;
}

.info-modal-tel {
    margin: 3px;
    width: 23px;
    height: 23px;
    color: rgb(34, 67, 114);
    background: rgb(199, 230, 249);
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.slot {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 11px;
    justify-content: space-between;
    margin-bottom: 5px;
}

.slot-registracion {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mp-switch {
    margin-bottom: 0;
    position: relative;
    top: 2px;
}

.tel {
    position: relative;
    right: 1px;
}

.booleanos-medio-pago hr {
    width: 100%;
}

.popup-icono {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 0em auto 0em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-color: #3085d6;
    color: #3085d6;
}

.icono {
    display: flex;
    justify-content: center;
    font-size: 3.75em;
    text-align: center;
}

.mensaje-leido {
    width: 33%;
}

@media (max-width: 420px) {
    .popup {
        top: 50%;
        left: 50%;
        margin-left: -175px;
        margin-top: -263px;
        //width: 150px;
        max-width: 350px;
    }

    .popUpNotif {
        min-height: 540px;
        width: 300px;
        top: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -250px;
    }

    .mensaje-leido {
        width: 100%;
    }
}

.confirmar-actualizar-botones {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    height: 42px;

    .confirmar-boton-datos .actualizar-boton-datos {
        align-items: center;
    }
}

.confirmar-boton-datos {
    cursor: pointer;
}

.actualizar-boton-datos {
    cursor: pointer;
}

.swalIconCustom .swal2-icon.swal2-success {
    border-color: #57b61f;
    color: #57b61f;
}

.swalIconCustom .swal2-icon.swal2-warning {
    border-color: #d33 !important;
    color: #d33 !important;
}

.swalIconCustom .swal2-icon.swal2-info {
    border-color: #3085d6 !important;
    color: #3085d6 !important;
}

.swalIconCustom .swal2-icon.swal2-error {
    border-color: #d33 !important;
    color: #d33 !important;
}

.swal2-container {
    z-index: 106099999 !important;
    position: relative;
}

@media (max-width: 322px) {
    .popup {
        top: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -210px;
        max-width: 350px;
        min-height: 420px;
        width: 300px;
    }

    .popup-mensaje {
        font-size: 1.1em;
    }
}

.card-estilo {
    font-size: 14px;
    background-color: #ea3f3f;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    cursor: pointer;
}

@media (max-width: 420px) {
    .prod-card {
        width: 100%;
    }
}

.mic-svg {
    position: relative;
    bottom: 51px;
    left: calc(100% - 30px);
}

.card-estilo {
    font-size: 14px;
    background-color: #ea3f3f;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    cursor: pointer;
}

.rounded-checkbox {
    width: 13px;
    height: 13px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.rounded-checkbox:checked {
    background-color: blue;
    border: 2.5px solid rgb(221, 221, 221);
}

.span-item-nov.div-item-nov {
    padding: 5px 0;
}

.span-item-nov {
    font-weight: 700;
    font-size: 16px;
}

.daysNotif {
    background: white;
    min-height: 48px;
    margin-bottom: 12px;
    padding: 0px 12px 0px 6px;
    border-radius: 6px;
    cursor: "pointer";
}

// Estilos para alta de suscripciones

.suscripciones-nombreapellido {
    display: inline-block;
    width: 300px;
    margin-top: 5px;
}

.suscripciones-carrito-card {
    background: white;
    min-height: 48px;
    margin-bottom: 12px;
    padding: 0px 12px 0px 6px;
    border-radius: 6px;
    cursor: "pointer";
}

.suscripciones-prod-card {
    flex: 0 1 calc(25% - 1em);
    background-color: white;
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
}

.alta-suscripciones-select-tipodocumento {
    width: 100px;
}

.alta-suscripciones-select {
    // margin-bottom: 28px;
    font-size: $fontsize;
    line-height: 24px;
    width: 100%;
    height: 34px;
    position: relative;
    background-color: white;
    font-family: Roboto, sans-serif;
    border-radius: 6px;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
}

#precioSAPConsultar {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100vh; /* Establece la altura al 100% del viewport */
    background-color: white;
}

.circuloPrecioSAP {
    border: 3px solid $precioSAPColor;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px auto;
    padding: 3%;
    width: 298px;
    height: 190px;
    background: #fff0;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 17px;
    padding-bottom: 5px;
}

.precioSAPTapa {
    color: $precioSAPColor;
    /*font-family: fantasy;*/
    font-family: sans-serif;
    font-size: 40px;
    /*margin-bottom: 12px;*/
    display: inline-flex;
    align-items: center;
    text-align: center;
}
.precioSAPTapaSigno {
    color: $precioSAPColor;
    /*font-family: fantasy;*/
    font-family: sans-serif;
    font-size: 57px;
    /* margin-bottom: 10px;*/
    margin-right: 6px;
    display: flex;
}

.precioSAPTapaEntera {
    color: $precioSAPColor;
    /*font-family: fantasy;*/
    font-family: sans-serif;
    font-size: 58px;
    /*margin-bottom: 12px;*/
    display: flex;
}

.precioSAPTapaDecimal {
    color: $precioSAPColor;
    /*font-family: fantasy;*/
    font-family: sans-serif;
    font-size: 27px;
    margin-bottom: 21px;
    display: flex;
    text-decoration: underline;
}
.precioSAPRecargo {
    color: $precioSAPColor;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: -7px;
}

.precioSAPBotton {
    color: $precioSAPColor;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-top: 16px;
}

.barraCerrarPrecioSAP {
    background: #ffffff00;
    color: $precioSAPColor;
    border: 2px solid $precioSAPColor;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    border-radius: 16px;
    width: 105px;
    height: 33px;
    font-weight: bold;
    bottom: 56px;
    left: 0px;
    right: 0px;
}

@media (min-width: 1001px) and (max-width: 1220px) {
    .suscripciones-prod-card {
        flex: 0 1 calc(32%);
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .suscripciones-prod-card {
        flex: 0 1 calc(48%);
    }
}
@media (max-width: 800px) {
    .suscripciones-prod-card {
        flex: 0 1 calc(100%);
    }

    .suscripciones-nombreapellido {
        display: block;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .elemento-botones-input-siguiente-edicion {
        margin-top: 40px;
    }
}

@media (max-width: 432px) {
    .card-siguiente-edicion {
        margin-top: 5px !important;
    }
}

/**************************************************************
* Media queries para etiquetas "Duplica tus puntos" y "Oferta"
***************************************************************/
.constainerMultiplicaPuntosItem {
    width: fit-content;
    height: fit-content;
}

.titleTagDescuentoItemSinPromo {
    height: 25px;
    padding: 5px 60px 0px 10px;
    font-size: 14px;
    font-weight: 500;
    margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
    border-radius: 4px;
    background-color: #49bcf2;
    color: white;
    position: relative;
    right: 2em;
}

@media (max-width: 300px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 40px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 3rem;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        right: 1rem;
    }
}

@media (min-width: 300px) and (max-width: 350px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 40px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 0.5rem;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        right: 1rem;
    }
}

@media (min-width: 351px) and (max-width: 400px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 40px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 1.5rem;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        // right: 1rem;
    }
}

@media (min-width: 400px) and (max-width: 450px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 40px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 2.5rem;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        left: 1rem;
    }
}

@media (min-width: 451px) and (max-width: 480px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 40px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 4.5em !important;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        left: 3em;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 20px 0px 20%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 8.5em;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        left: 1em;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 0px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 5.5em;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        // margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
        // right: 9em;
    }
}

@media (min-width: 992px) and (max-width: 1022px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 0px;
        padding: 0px 0px 0px 20%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 9em;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
    }
}

@media (min-width: 1023px) and (max-width: 1999px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px -15px;
        padding: 0px 0px 0px 10%;
        position: absolute;
    }

    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 2em;
    }

    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
        position: relative;
    }
}

@media (min-width: 1200px) {
    .tags-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0px 10px 0px 5px;
        padding: 0px 0px 0px 10%;
        position: absolute;
    }

    //una linea
    .titleTagDescuentoItem {
        height: 25px;
        padding: 5px 60px 0px 10px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px calc(50% - 10%) 0px calc(50% - 10%);
        border-radius: 4px;
        background-color: #49bcf2;
        color: white;
        position: relative;
        right: 5em;
    }

    // una sola linea
    .titleTagMultiplicaPuntosItem {
        min-width: 30px;
        max-width: 125px;
        padding: 5px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        background-color: #b090c8;
        color: white;
    }
}

/*******************************************************
********************************************************/

div.modal-singlebutton {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: stroke;
    z-index: 1001;
}

div.modal-content {
    width: 500px;
    background-color: white;
    border-radius: 10px;

}

div.modal-body {
    height: 300px;
    overflow-y: auto;
}

div.modal-title {
    margin: auto;
    font-size: 24px;
    font-weight: 300;
    color: #224372;
}

button.close-button {
    margin: auto;
    margin-top: 15px;
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em;
    padding: 0.625em 2em;
    box-shadow: none;
    font-weight: 500;
}

div.modal-body::-webkit-scrollbar {
    width: 10px;
    display: inline;
  }
  
div.modal-body::-webkit-scrollbar-track {
background: #bbbaba; 

  } 
   
div.modal-body::-webkit-scrollbar-thumb {
background: rgb(94, 93, 93); 
}
  
div.modal-body::-webkit-scrollbar-thumb:hover {
background: rgb(68, 67, 67); 
}
.back-arrow-box-notif {
    top: 0;
    margin-top: 14px;
    margin-left: 22px;
    z-index: 99999999;
    left: 1em;
    color: #343435;
    width: 13px;
    height: 13px;
    cursor: pointer;
    position: fixed;
}

.modal-body-notificacion {
    border: 0!important;
    padding-bottom: 10px;
    min-height: 125px;
    max-height: 300px;
    overflow-y: auto;
    padding: 1rem;
}

div.modal-body-notificacion::-webkit-scrollbar {
    width: 10px;
    display: inline;
}
  
div.modal-body-notificacion::-webkit-scrollbar-track {
    background: #bbbaba; 
} 
   
div.modal-body-notificacion::-webkit-scrollbar-thumb {
    background: rgb(94, 93, 93); 
}
  
div.modal-body-notificacion::-webkit-scrollbar-thumb:hover {
    background: rgb(68, 67, 67); 
}

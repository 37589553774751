.back-arrow-box-notif {
    top: 0;
    margin-top: 14px;
    z-index: 99999999;
    left: 1em;
    color: #343435;
    width: 13px;
    height: 13px;
    cursor: pointer;
    position: fixed;
}

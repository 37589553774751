div.modal-noedit {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

div.modal-content-noedit {
    background-color: white;
    width: 300px;
    border-radius: 10px;
    border: 1px solid gray;
}